@import '../../assets/sass/app.scss';

.app-body {
    display: flex;
    flex-direction: row;
    // height: 100vh;
    .navigation {
        background-color: $grey;
    }
}
.page {
    background-color: $background;
    padding: 18px 28px 0 18px;
    width: 100%;
}

@media (max-width: 880px){

           .page {
            padding: 18px 0px 0px 0px;
        }
    }