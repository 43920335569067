@import '../../assets/sass/app.scss';

.history-container {
    .cards-container {
        .cards {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}
.table-container {
    .title {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 1em;
        font-weight: 800;
    }
}
@media screen and (max-width: 1024px) {
    .reports-container, .report-item-container {
        margin-top: 40px;
    }
}