@import '../../assets/sass/app.scss';
.card-navigation {
	margin: 20px 0 !important;
}
.card-arrow-right {
	margin-left: 10px !important;
    margin-top: 15px !important;
}
.card-arrow-left {
	margin-left: 15px !important;
    margin-top: 15px !important;
}
.page-numbers {
    position: relative;
	text-align: center;
	padding: 20px 0;
	font-size: 0;
	z-index: 6;
	margin: 50px 0;
	
	animation: cdp-in 500ms ease both;
	li {
		font-size: 14px;
		text-decoration: none;
		
		display: inline-block;
		text-transform: uppercase;
		margin: 0 3px 6px;
		height: 38px;
		min-width: 38px;
		border-radius: 38px;
		border: 2px solid $recce-maroon;
		line-height: 38px;
		padding: 0;
		color: $recce-maroon;
		font-weight: 700;
		letter-spacing: .03em;


		display: inline-block;

	}
	.previous-next {
		padding: 0 16px;
		margin: 0 12px 6px;
	}
	.active {
		background-color: $recce-maroon;
		color: $white;
	}
	.dots {
		border: unset;
		border-radius: unset;
	}
	.dots:hover {
		background-color: unset;
		color: unset;
		cursor: unset;
	}
	li:hover {
		background-color: $recce-maroon;
		color: $white;
		cursor: pointer;
	}
}
  
//   .pageNumbers li {
//     padding: 3px 7px;
//     border: 1px solid black;
//     cursor: pointer;
//     align-self: center;
//   }
  
//   .pageNumbers li.active {
//     background-color: $grey;
//     color: $white;
//   }
  
//   .pageNumbers li button {
//     background-color: transparent;
//     border: none;
//     color: black;
//     cursor: pointer;
//   }
//   .pageNumbers li button:hover {
//     background-color: $grey;
//     color: $white;
//   }
//   .pageNumbers li button:focus {
//     outline: none;
//   }
  
//   .loadmore {
//     padding: 1rem;
//     background-color: transparent;
  
//     color: black;
//     font-size: 1.2rem;
//     border: 1px solid black;
//     cursor: pointer;
//   }

@keyframes cdp-in {
	from {
		transform: scale(1.5);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}