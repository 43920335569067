@import '../../assets/sass/app.scss';
.form-container {
    h1 {
        padding: 0 0 0 15px;
        font-weight: 300;
    }
    .delete-section-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $charcoal;
        width: 25px;
        height: 25px;
        background-color: $charcoal;
        border-radius: 25px;
        position: relative;
        top: 25px;
        left: -10px;
        cursor: pointer;
    }
    .delete-section-icon:after {
        content: '';
        height: 20px;
        border-left: 2px solid $white;
        position: absolute;
        transform: rotate(45deg);
    }
    .delete-section-icon:before {
        content: '';
        height: 20px;
        border-left: 2px solid $white;
        position: absolute;
        transform: rotate(-45deg);
    }
    .show-input-section {
        border: 1px solid $disabled-grey;
        margin: 10px 0;
        border-radius: 5px;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
    }
    .contacts {
      display: flex;
      flex-direction: row;
    }
	.clone-inputs-section {
		display: flex;
		justify-content: flex-end;
		.clone-input {
			width: 300px;
			color: $recce-maroon;
			padding: unset;
		}
	}
    .inputs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px 15px;
        text-align: center;
        .inputs-label {
            padding: 15px;
            font-weight: 600;
        }
        .input-item-small {
            .star {
                visibility: hidden;
                font-size: 20px;
                cursor: pointer;
                color: $recce-maroon;
				position: relative;
				top: -10px;
            }
            .star:before {
                content: "\2606";
                visibility: visible;
            }
            .star:checked:before {
                content: "\2605";
            }
        }
        .password-item {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
		.input-control-item {
			min-width: 200px;
			padding-top: 5px;
            padding-right: 5px;
            padding-left: 5px;
            flex-grow: 1;
		}
        .input-item {
            min-width: 200px;
			padding-top: 5px;
            padding-right: 5px;
            padding-left: 5px;
            flex-grow: 1;
            .link-button {
                border: 0;
                background-color: transparent;
                color: $grey;
                font-size: 16px;
            }
            .link-button:hover {
                color: $charcoal;
                text-decoration: underline;
                cursor: pointer;
            }
            .required {
                color: $failure;
            }
            label {
                display: flex;
                font-size: 10px;
                height: 10px;
                text-transform: uppercase;
            }
            input,
            textarea {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 16px;
                display: block;
                width: 100%;
                padding: 10px 1px;
                border: 0;
                border-bottom: 1px solid $disabled-grey;
                outline: none;
                transition: all .2s;
                &::placeholder {
                    text-transform: uppercase;
                }
                &:focus {
                    border-color: $charcoal;
                }
            }
			input[type='time']::-webkit-calendar-picker-indicator {
				background: none;
			}
			input[type='time']::-webkit-datetime-edit-ampm-field {
				display: none;
			}
            .normal-text {
                &::placeholder {
                    text-transform: unset !important;
                }
            }
			ul {
				position: relative;
				top: -27px;
				display: flex;
				flex-direction: column;
				margin-block-start: unset;
				padding-inline-start: unset;
				margin-block-end: unset;
				max-height: 180px;
				overflow-y: auto;
				padding: 10px 0;
				border-radius: 10px;
				box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
				li {
					position: relative;
					left: 20px;
					display: flex;
					padding: 20px 0;
					// margin: 0 20px;
					list-style: none;
					border-bottom: 1px solid $disabled-grey;
					width: 90%;
				}
			}
        }
        .med-item {
            max-width: 400px;
        }
        a {
            text-decoration: none;
            color: $grey;
        }
    }
	.reverse {
		flex-wrap: wrap-reverse !important;
	}
}

.actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
}

.change-pswrd {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        width: 50%;
        border: none;
        padding: 18px;
        cursor: pointer;
        text-transform: uppercase;
        background: $disabled-grey;
        color: $white;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 0;
        letter-spacing: 0.2px;
        outline: 0;
        transition: all .3s;
        &:hover {
            background: $unselected;
        }
    }
}

.sub-form-section {
    margin-bottom: 40px;
    .title {
        font-size: 18px;
        font-weight: 600;
        padding-left: 20px;
    }
}

.title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1em;
    font-weight: 800;
}
.counter {
	position: relative;
    right: 10px;
    width: 10px;
    padding: 10px 15px;
    border: solid 1px $disabled-grey;
    background-color: $recce-maroon;
    color: white;
    border-radius: 50%;
	z-index: 2;
}

.tooltip-info {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

[data-tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all data-tooltips */
[data-tooltip]::before,
[data-tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: .9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[data-tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[data-tooltip]::after {
  content: attr(data-tooltip); /* magic! */
  
  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;
  
  /* 
    Let the content set the size of the data-tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  margin-left: 6.5rem;
  
  // max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: .3ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the data-tooltips respond to hover */
[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  display: block;
}

/* don't show empty data-tooltips */
[data-tooltip='']::before,
[data-tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[data-tooltip]:not([data-flow])::before,
[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow^="up"]::before,
[data-tooltip][data-flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */
[data-tooltip][data-flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[data-tooltip][data-flow^="down"]::after {
  top: calc(100% + 5px);
}
[data-tooltip][data-flow^="down"]::before,
[data-tooltip][data-flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[data-tooltip][data-flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[data-tooltip][data-flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[data-tooltip][data-flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[data-tooltip][data-flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@keyframes data-tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes data-tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[data-tooltip]:not([data-flow]):hover::before,
[data-tooltip]:not([data-flow]):hover::after,
[data-tooltip][data-flow^="up"]:hover::before,
[data-tooltip][data-flow^="up"]:hover::after,
[data-tooltip][data-flow^="down"]:hover::before,
[data-tooltip][data-flow^="down"]:hover::after {
  animation: data-tooltips-vert 300ms ease-out forwards;
}

[data-tooltip][data-flow^="left"]:hover::before,
[data-tooltip][data-flow^="left"]:hover::after,
[data-tooltip][data-flow^="right"]:hover::before,
[data-tooltip][data-flow^="right"]:hover::after {
  animation: data-tooltips-horz 300ms ease-out forwards;
}