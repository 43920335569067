.item-info-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    border: solid 1px $light-border;
    border-radius: 5px;
    background-color: $disabled-grey;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
    .items {
        display: flex;
        flex-direction: column;
        color: $recce-maroon;
        justify-content: center;
        div {
            padding-bottom: 10px;
            .label { 
                color: $charcoal;
                font-weight: 600;
                text-transform: capitalize;
            }
        }
        div:last-child {
            padding-bottom: 0;
        }
    }
}

.ratios {
    display: flex;
    .ratio {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 110px;
        .title {
            color: $black;
            font-size: 1.5em;
            text-transform: capitalize;
        }
        .bubble {
            display: flex;
            justify-content: center;
            width: 50px;
            color: $white;
            background-color: $recce-maroon;
            padding: 30px 15px;
            border-radius: 50%;
            font-weight: 800;
        }
    }
}
.single {
    display: unset !important;
}