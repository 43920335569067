@import '../../assets/sass/app.scss';

.camera-container {
    position: relative;
    overflow-x: hidden;
    .camera {
        position: relative;
        video {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
        .icons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: $recce-maroon;
            margin: 20px 0;
            div {
                border: solid 1px $recce-maroon;
                padding: 15px;
                border-radius: 50%;
                cursor: pointer;
            }
            
        }
    }
    
    .camera-result {
        position: absolute;
        top: 0;
        left: 105%;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.9);
        transition: .4s;
        canvas {
            width: 100%;
            height: auto;
        }
        .actions {
            position: absolute;
            bottom: 0;
            width: 100%;
            right: 0;
        }
    }
    .has-photo {
        left: 0;
    }
}