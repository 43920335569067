@import '../../assets/sass/app.scss';

.left-nav {
    width: 80px;
    
    background-color: $white;
    .menu-container {
        height: 50px;
        background-color: $grey;
    }
    .menu-toggle {
        // opacity: 0;
        display: none;
    }
    .menu-toggle:checked ~ .menu-btn > span {
        transform: rotate(45deg);
    }
    .menu-toggle:checked ~ .menu-btn > span::before {
        top: 0;
        transform: rotate(0);
    }
    .menu-toggle:checked ~ .menu-btn > span::after {
        top: 0;
        transform: rotate(90deg);
    }
    .menu-toggle:checked ~ .menus {
        width: 200px;
        .menu {
            left: 0px;
            width: 200px;
            .item-label {
                display: block;
            }
        }
    }
    .menu-btn {
        display: flex;
        align-items: center;
        position: fixed;
        top: 12px;
        left: 20px;
        width: 26px;
        height: 26px;
        cursor: pointer;
        margin-left: 7px;
        z-index: 5;
    }
    .menu-btn > span,
    .menu-btn > span::before,
    .menu-btn > span::after {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $charcoal;
        transition-duration: .25s;
    }
    .menu-btn > span::before {
        content: '';
        top: -8px;
    }
    .menu-btn > span::after {
        content: '';
        top: 8px;
    }
    li {
        height: 45px;
    }
    .menus {
        display: flex;
        flex-direction: column;
        -webkit-box-pack: justify;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        top: 50px;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
        width: 80px;
        background-color: $disabled-grey;
        transition-duration: .25s;
        overflow-y: auto;
        z-index: 9;
        .menu {
            display: block;
            width: 80px;
            margin: 0;
            padding: 30px 0 0 0;
            list-style: none;
            background-color: $disabled-grey;
            border-right: $light-border;
            transition-duration: .25s;
            z-index: 4;
        }
        .bottom-menu {
            padding: 0 0 80px 0;
            margin: 0;
        }
    }
    .menu-item {
        display: flex;
        flex-direction: row;
        padding: 12px 32px;
        font-size: 20px;
        font-weight: 600;
        text-decoration: none;
        transition-duration: .25s;
        cursor: pointer;

        svg {
            // display: inline-flex;
            // vertical-align: middle;
            width: 30px;
            margin-left: -7px;
            color: $black;
        }
        .icon-rotate {
            transform: rotate(180deg);
        }
        .signout {
            background-color: $grey;
            padding: 10px;
            margin-left: -14px;
            border-radius: 32px;
        }
    }
    .menu-item-selected {
        background-color: $recce-maroon;
        color: $white;
        svg {
            fill: $white;
            color: $white
        }
    }
    .menu-item:hover {
        background-color: $recce-maroon;
        color: $white;
        svg {
            fill: $white;
            color: $white;
        }
    }
    .signout-item {
        display: flex;
        align-items: center;
        margin-top: 70px;
    }
    .no-hover:hover {
        background-color: white !important;
        color: $unselected !important;
    }
    .item-label {
        display: none;
        padding-left: 21px;
        font-size: 18px;
        font-weight: 600;
    }
    .information-section {
        margin-top: 200px;
        .information {
            background-color: $disabled-grey;
            color: $white;
            border-radius: 20px;
            padding: 0 10px;
            height: 25px;
            margin-left: -5px;
        }
    }
    .divider {
        border-bottom: solid 2px $disabled-grey;
        margin: 0 10px;
    }
}

@media screen and (max-width: 1024px) {
    .left-nav {
        position: fixed;
        z-index: 2;
        .menu-toggle:checked ~ .menus {
            left: 0;
            width: 200px;
            .menu {
                left: 0px;
                width: 200px;
                .item-label {
                    display: block;
                }
            }
        }
        .menus {
            left: -80px;
        }
    }
    
}