@import '../../assets/sass/app.scss';

.projects-container {
    .cards-container {
        .cards {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}
.project-item-container {
    padding: 10px;
    .project-description {
        display: flex;
        flex-direction: column;
        padding: 10px;
        .title {
            font-size: 1.2em;
            color: $charcoal;
            font-weight: 600;
        }
        div {
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .projects-container, .project-item-container {
        margin-top: 40px;
    }
}