@import '../variables/colors.scss';

.arrow {
    box-sizing: border-box;
    height: 15px;
    width: 15px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    margin-right: 15px;
    margin-top: -3px;
    color: $black;
}
.arrow:hover {
    cursor: pointer;
}
.up-arrow {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(225deg);
    margin-right: 15px;
    margin-top: 8px;
    color: $black;
}
.up-arrow:hover {
    cursor: pointer;
}
.right-arrow {
    box-sizing: border-box;
    height: 10px;
    width: 10px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(-45deg);
    margin-right: 15px;
    margin-top: 0px;
}
.left-arrow {
    box-sizing: border-box;
    height: 10px;
    width: 10px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(135deg);
    margin-right: 15px;
    margin-top: 0px;
}
.clickable-item:hover, .left-arrow:hover, .right-arrow:hover {
    cursor: pointer;
    text-decoration: underline;
}
.clickable-item {
  color: #4a0101;
  font-weight: bold;
}
.heading-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .new-item-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        div:nth-child(odd) {
            margin-right: 10px;
            background-color: $charcoal !important;
        }
    }
}
.item-detail-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .item:first-child {
        margin-right: 15px;
    }
    .item {
        flex-grow: 1;
        // margin-right: 25px;
        padding: 15px 0 15px 0;
        min-width: 400px;
        .title {
            font-size: 20px;
            padding-bottom: 15px;
            margin-bottom: 5px;
            border-bottom: solid 1px $unselected;
        }
        .title-items {
            display: flex;
            justify-content: space-between;
            .title-actions {
                display: flex;
                button:first-child {
                    margin-right: 5px;
                }
            }
        }
        .subtitle {
            display: flex;
            justify-content: center;
            font-size: 16px;
            color: $grey;
            margin-top: 5px;
        }
        .detail {
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            margin: 5px 0;
            div:first-child {
                font-weight: 600;
            }
            .bubble {
                display: flex;
                justify-content: center;
                width: 50px;
                color: $white;
                background-color: $recce-maroon;
                padding: 30px 15px;
                border-radius: 50%;
                font-weight: 800;
            }
        }
    }
}
.new-table-item {
    background-color: $recce-maroon;
    color: $white;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
}
.actions {
    display: flex;
    flex-direction: row;
    button {
        width: 100%;
        border: none;
        padding: 18px;
        cursor: pointer;
        text-transform: uppercase;
        background: $disabled-grey;
        color: $white;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 0;
        letter-spacing: 0.2px;
        outline: 0;
        transition: all .3s;
        &:hover {
            background: $unselected;
        }
        &:nth-child(2) {
            background: $grey;
            color: $white;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 4px;
            &:hover {
                background: $charcoal;
            }
        }
    }
}
.filter-section {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .input-item {
        min-width: 200px;
        padding: 12px 5px;
        flex-grow: 1;
        input {
            background-color: $background;
            font-size: 16px;
            display: block;
            width: 100%;
            padding: 10px 1px;
            border: 0;
            border-bottom: 1px solid $disabled-grey;
            outline: none;
            transition: all .2s;
            &::placeholder {
                text-transform: uppercase;
            }
            &:focus {
                border-color: $charcoal;
            }
        }
    }
}
.tabs-container {
    box-sizing: content-box;
    // max-width: 60em;
    margin: 0 auto 48px auto;  
    padding: 12px 0;
    height: fit-content;
    .tabs {
        display: flex;
        // flex-wrap: wrap;
        // position: relative;
    }
    .tab-content {
        border-top: solid 5px $recce-maroon;
        .content-info {
            margin-top: 20px;
            border-top: solid 1px $grey;
            border-right: solid 1px $grey;
            border-left: solid 1px $grey;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
}


.unpinned {
    font-size: 2.5em; 
    position: relative; 
    display: inline-block; 
    background-color: gray; 
    width: .2em;
    height: .3em; 
    margin: auto 1.15em .35em; 
    border-width: .08em .1em .1em; 
    border-style: solid; 
    border-color: gray white; 
    -webkit-transform: rotate(45deg); 
    transform: rotate(45deg); 
    border-radius: .1em .1em 0 0;
}
.unpinned:before {
    content: ""; 
    background-color: gray; 
    position: absolute; 
    top: 0;
    left: .08em; 
    width: 2px;
    height: .75em; 
    border-radius: 0 0 .05em; 
}
.spinner-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .spinner {
        position: relative;
        height: 50px;
        width: 50px;
        border: 2px solid transparent;
        border-top-color: $black;
        border-left-color: $black;
        border-radius: 50%;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
    .spinner::before {
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        content: "";
        border: 2px solid transparent;
        border-top-color: #BA68C8;
        border-left-color: #BA68C8;
        border-radius: 50%;
        -webkit-animation: spinBack 1s linear infinite;
        animation: spinBack 1s linear infinite;
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spinBack {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-720deg);
        transform: rotate(-720deg);
    }
}
@keyframes spinBack {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-720deg);
        transform: rotate(-720deg);
    }
}
.pinned-item {
    -webkit-transform: rotate(-45deg); 
    transform: rotate(-45deg); 
}

@media (max-width: 880px){
    .structure-item-container{
        .item {
            min-width: 100% !important;
        }
    }
  }
