@import '../../assets/sass/app.scss';
.heading {
    margin-bottom: 20px;
    label {
        font-size: 2em;
        font-weight: 300;
    }
}
.click:hover {
    cursor: pointer;
}
label.click {
    color: #4a0101;
}