@import '../../assets/sass/app.scss';
.tasks-container {
    .heading-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .new-table-item {
            background-color: $charcoal;
            color: $white;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            border-radius: 20px;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .cards-container {
        .cards {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}
.task-item-container {
    padding: 10px;
    .heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .timer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .timer-inputs {
                padding: 0 !important;
                margin: 20px;
                .timer-input-item {
                    min-width: 80px !important;
                    width: 80px;
                }
            }
        }
        .icons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: $recce-maroon;
            // margin: 25px;
            cursor: pointer;
        }
    }
    .input-item {
        label {
            color: $recce-maroon;
        }
        input[type="text"] {
            border-bottom: 0;
        }
        .info-input {
            background-color: $disabled-grey;
        }
    }

    .task-description {
        display: flex;
        flex-direction: column;
        padding: 10px;
        .title {
            font-size: 1.2em;
            color: $charcoal;
            font-weight: 600;
        }
        div {
            margin-bottom: 10px;
        }
    }
    .structure-map {
        flex: 1 ;
    }
    .task-table {
        flex: 0 66.66%;
    }
}

@media screen and (max-width: 1240px) {
    .tasks-container , .task-item-container {
        margin-top: 40px;
        
    }
    .task-item-container {
    .item-info-section {
        .items {
            .row {
                flex-direction: column;
            }
            .col {
                width: 100%;
            }
        }
    }
    }
}