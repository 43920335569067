@import '../../assets/sass/app.scss';

.tab-item-input {
    display: none; 
}
.tab-item-input:checked + label {
    background: $recce-maroon;
    color: $white; 
}
.tab-item-label {
    transition: background 0.4s ease-in-out, height 0.2s linear;
    display: inline-block;
    cursor: pointer;
    color: $charcoal;
    width: 20%;
    height: 3em;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    text-align: center;
    line-height: 3em; 
    margin-right: 5px;
    &:last-of-type {
        border-bottom: none; 
    }
    &:hover {
        cursor: pointer;
    }
}
.tab-item-label:last-of-type {
    border-bottom: none; 
}
.tab-item-label:hover {
    background: $recce-maroon;
    color: $white;
}

@media screen and (max-width: 1600px) {
    .tab-item-label {
        min-width: 130px;
        width: 15%; 
    } 
}