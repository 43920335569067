@import '../../../assets/sass/app.scss';

.select-container {
    position: relative;
    .select {
        font-size: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        // padding: 10px 0;
        border: 0;
        border-bottom: 1px solid $disabled-grey;
        outline: none;
        transition: all .2s;
        .label {
            display: flex;
            width: 200px;
            input[type='text'] {
                font-family: unset;
                text-transform: capitalize;
                border-bottom: unset !important;
            }
            div {
                font-family: unset;
                text-transform: capitalize;
                display: flex;
                justify-content: flex-start;
                width: 100%;
                padding: 10px 1px;
            }
            .placeholder {
                text-transform: uppercase;
                color: $grey;
            }
        }
        .display-value {
            color: $recce-maroon;
            padding-right: 5px;
        }
        .display-value:nth-child(even){
            font-weight: 800;
        }
    }
    .disabled {
        pointer-events: none;
        color: $disabled-grey;
        input[type="text"] {
            color: $disabled-grey;
        }
        .arrow {
            color: $disabled-grey;
        }
    }
    .items {
        margin: 0;
        padding: 0;
        position: absolute;
        background-color: $white;
        z-index: 3;
        max-height: 300px;
        overflow-y: auto;
        cursor: pointer;
        .item {
            display: flex;
            align-items: center;
            height: 60px;
            color: $charcoal;
            border-right: solid 1px $disabled-grey;
            border-left: solid 1px $disabled-grey;
            border-bottom: solid 1px $disabled-grey;
            div {
                padding-left: 12px;
            }
        }
        .item:hover {
            background-color: $recce-maroon;
            color: $white;
        }
        .checkbox-container {
            display: block;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            .title {
                font-size: 16px;
            }
        }
        /* Hide the browser's default checkbox */
        .checkbox-container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        /* Create a custom checkbox */
        .checkbox {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #eee;
            border: 1px solid black;
            margin-left: 10px;
        }
        /* On mouse-over, add a grey background color */
        .checkbox-container:hover input ~ .checkbox {
            background-color: $disabled-grey;
        }
        /* When the checkbox is checked, add a blue background */
        .checkbox-container input:checked ~ .checkbox {
            background-color: $recce-maroon-light;
        }
        /* Create the checkmark/indicator (hidden when not checked) */
        .checkbox:after {
            content: '';
            position: absolute;
            display: none;
        }
        /* Show the checkmark when checked */
        .checkbox-container input:checked ~ .checkbox:after {
            display: block;
        }
        /* Style the checkmark/indicator */
        .checkbox-container .checkbox:after {
            left: 7px;
            top: 2px;
            width: 3px;
            height: 10px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    .up-item {
        top: -300px;
        .item:first-child {
            border-top: solid 1px $disabled-grey;
        }
    }
    .disabled {
        pointer-events: none;
        opacity: 0.85;
    }
}