@import '../../assets/sass/app.scss';

.chart-container {
    margin: 15px 0;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 20px;
        padding-bottom: 15px;
        margin-bottom: 5px;
        border-bottom: solid 1px $unselected;
        .selected-title {
            color: $success;
            font-size: 18px;
            .icon {
                color: $charcoal;
            }
        }
    }
    .chart-info {
        position: relative;
        height: 220px;
        overflow-y: auto;
    }
    .footer {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: $grey;
        margin-top: 20px;
        .completed {
            color: $success;
        }
        .overdue {
            color: $failure;
        }
        .pending {
            color: $grey;
        }
        span {
            font-weight: 800;
        }
    }
}