@import '../../../../assets/sass/app.scss';

$width: 35px;
$height: $width;
$small-width: 25px;
$small-height: 25px;

.small-btn {
    position: relative;
    display: block;
    width: $small-width;
    height: $small-height;
    background-color: $recce-maroon;
    cursor: pointer;
    border: 2px solid $recce-maroon;
    transition: 0.2s all ease-out;
    border-radius: 50%; 
	&:hover {
		background-color: $light-border;
	  }
	
	  &:active,
	  &:focus {
		transform: scale(0.95, 0.95);
	  }
	
	  &.stop {
		&::after {
		  content: "";
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  display: block;
		  width: ($small-width/2.5);
		  height: ($small-height/2.5);
		  background-color: $white;
		  margin-top: 0 - ($small-height/5);
		  margin-left: 0 - ($small-width/5);
		}
	  }
	
	  &.pause {
		&::after,
		&::before {
		  content: "";
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  display: block;
		  width: ($small-width/6);
		  height: ($small-height/2);
		  background-color: $white;
		  margin-top: 0 - ($small-height/4);
		  margin-left: 0 - ($small-width/4);
		}
	
		&::after {
		  left: auto;
		  right: 50%;
		  margin-left: auto;
		  margin-right: 0 - ($small-width/4);
		}
	  }
	
	  &.play {
		&::after {
		  content: "";
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  display: block;
		  width: 0;
		  height: 0;
		  border-style: solid;
		  border-color: transparent;
		  border-width: ($small-width/4);
		  border-left-width: ($small-width/2.83);
		  border-left-color: $white;
		  margin-top: 0 - ($small-height/4);
		  margin-left: 0 - ($small-width/7);
		}
	}
}
.btn {
    position: relative;
    display: block;
    width: $width;
    height: $height;
    background-color: $recce-maroon;
    cursor: pointer;
    border: 2px solid $recce-maroon;
    margin: 5px;
    transition: 0.2s all ease-out;
    border-radius: 50%;
  
    &:hover {
      background-color: $light-border;
    }
  
    &:active,
    &:focus {
      transform: scale(0.95, 0.95);
    }
  
    &.stop {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: ($width/2.5);
        height: ($height/2.5);
        background-color: $white;
        margin-top: 0 - ($height/5);
        margin-left: 0 - ($width/6);
      }
    }
  
    &.pause {
      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: ($width/6);
        height: ($height/2);
        background-color: $white;
        margin-top: 0 - ($height/4);
        margin-left: 0 - ($width/4);
      }
  
      &::after {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: 0 - ($width/4);
      }
    }
  
    &.play {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
        border-width: ($width/4);
        border-left-width: ($width/2.83);
        border-left-color: $white;
        margin-top: 0 - ($height/4);
        margin-left: 0 - ($width/7);
      }
    }
}