@import '../../assets/sass/app.scss';

.table-action-container {
    max-height: 400px;
    overflow-y: auto;
    table {
        position: relative;
        thead {
            position: sticky;
            top: 0;
            z-index: 2;
            tr {
                th {
                    position: sticky;
                    top: 0;
                }
            }
        }
    }
}
table {
    // border: 1px solid $charcoal;
    border-collapse: collapse;
    // margin: 0;
    // padding: 0;
    // box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
    width: 100%;
    table-layout: fixed;
    thead {
        // background-color: $recce-maroon;
        background-color: $grey;
        tr {
            // height: 40px;
            height: 80px;
            th {
                color: $white;
                cursor: pointer;
                font-size: .8em;
                .col-labels {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    tbody {
        color: $charcoal;
        background-color: $white;
        a {
            color: $charcoal;
        }
        .row-spinner {
            background-color: $white !important;
        }
        tr {
            white-space: nowrap;
            font-size: 0.9em;
            padding: 16px 16px 16px 0;
            height: 80px;
            // height: 40px;
            // background-color: $unselected;
            td {
                text-align: center;
                div {
                    white-space: normal;
                    display: block;
                }
                .no-data {
                    font-size: 20px;
                    font-weight: 600;
                    color: $recce-maroon;
                }
                .actions-menu {
                    position: relative;
                    .more::after {
                        content: '\22EE';
                        font-size: 50px;
                        cursor: pointer;
                    }
                    .actions-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        position: absolute;
                        width: 200px;
                        height: 100px;
                        background-color: $disabled-grey;
                        color: $charcoal;
                        font-weight: 600;
                        z-index: 2;
                        top: -20px;
                        left: -180px;
                        font-size: 14px;
                        opacity: 0;
                        transition: .5s;
                        // border-top-left-radius: 20px;
                        // border-bottom-left-radius: 20px;
                        .action {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            padding-left: 30px;
                            text-transform: capitalize;
                            margin-bottom: 5px;
                            .icon {
                                margin-right: 10px;
                            }
                        }
                        .action:hover {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                        div:last-child {
                            margin-bottom: 0;
                        }
                    }
                    .show-actions {
                        opacity: 1;
                    }
                }
                .start-stop {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            td:hover {
                cursor: pointer;
            }
            .multi-items {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                div {
                    margin-top: 10px;
                }
            }
        }
        .selected-item {
            background-color: $recce-maroon;
            color: $white;
            a {
                color: $white;
            }
        }
        // tr:nth-child(even) {
        //     color: $white;
        //     background-color: $grey;
        //     a {
        //         color: $white;
        //     }
        // }
        .row-item:hover {
            background-color: $recce-maroon;
            color: $white;
            a {
                color: $white;
            }
        }
    }
}