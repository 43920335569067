body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hide {
  display: none;
}
 a:hover {
  text-decoration: underline;
}
a {
  font-weight: bold;
  color: #4a0101;
  text-decoration: none;
}

td a {
  color: #333333;
}

td a:hover {
  text-decoration: underline;
}

.Task-Comment-Next-Button {
  text-align: center;
  margin-top: 40px;
}

.Task-Comment-Next-Button button {
  color: #4a0101;
  font-weight: bold;
  font-size: 16px;
}
.click {
  color: #4a0101;
}
.click:hover {
  text-decoration: underline;
}
/* .otherContact-modal {
  display: flex;
  justify-content: space-between;
  padding: 20px;
} */
.otherContact-modal>* {
  margin: 0 10px;
  width: 38%;
}
.otherContact-modal {
  display: flex;
  justify-content: center;
  padding: 10px 25px 10px 25px;
}
.otherContact-availity {
  text-align: center;
  margin: 10px 0 10px 0;
}
.otherContact-main-modal {
  margin-bottom: 10px;
}
