@import '../../assets/sass/app.scss';

.table-container {
    margin-right: 10px;
}
.cards-container {
    .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}
.chart-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .chart-item {
        margin-top: 10px;
        margin-right: 10px;
        min-width: 400px;
        flex-grow: 1;
        width: 32%;
    }
}
.input-item {
    min-width: 200px;
    // max-width: 400px;
    // padding: 12px 5px;
    padding-right: 5px;
    padding-left: 5px;
    flex-grow: 1;
    .link-button {
        border: 0;
        background-color: transparent;
        color: $grey;
        font-size: 16px;
    }
    .link-button:hover {
        color: $charcoal;
        text-decoration: underline;
        cursor: pointer;
    }
    .required {
        color: $failure;
    }
    label {
        display: flex;
        font-size: 10px;
        height: 10px;
        text-transform: uppercase;
    }
    input,
    textarea {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        display: block;
        width: 100%;
        padding: 10px 1px;
        border: 0;
        border-bottom: 1px solid $disabled-grey;
        outline: none;
        transition: all .2s;
        &::placeholder {
            text-transform: uppercase;
        }
        &:focus {
            border-color: $charcoal;
        }
    }
    .normal-text {
        &::placeholder {
            text-transform: unset !important;
        }
    }
}

.hide {
    display: none;
}

@media screen and (max-width: 1024px) {
    .home-container {
        margin-top: 40px;
        .profile {
            position: absolute;
            right: 0;
            top: 10px;
        }
    }
}

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) {
//     .chart-list {
//         .chart-item {
//             width: 50%;
//         }
//     }
// }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) {
//     .chart-list {
//         .chart-item {
//             width: 33.333%;
//         }
//     }

// }
