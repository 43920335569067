@import '../../assets/sass/app.scss';

.map-container{
    height: 100vh;
}

.map-container-structures{
    height: 100vh;
}

@media (max-width: 880px){
	.map-container-structures {
		margin-top: 600px;
	}
}