@import '../../assets/sass/app.scss';

.list-container {
    margin: 15px 0;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 20px;
        padding-bottom: 15px;
        margin-bottom: 5px;
        border-bottom: solid 1px $unselected;
        .selected-title {
            color: $success;
            font-size: 18px;
            .icon {
                color: $charcoal;
            }
        }
    }
    .footer {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: $grey;
        margin-top: 20px;
        span {
            font-weight: 800;
        }
        .completed {
            color: $success;
        }
        .overdue {
            color: $failure;
        }
        .pending {
            color: $grey;
        }
    }
    ul {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-block-start: unset;
        padding-inline-start: unset;
        margin-block-end: unset;
        max-height: 220px;
        overflow-y: auto;
        color: $grey;
        li {
            display: flex;
            // padding: 20px 0;
            margin-bottom: 5px;
            list-style: none;
            border-bottom: solid 1px $disabled-grey;
            .indicator {
                height: 40px;
                width: 5px;
                // background-color: $failure;
            }
            .in-progress {
                background-color: $success;
            }
            .behind-schedule {
                background-color: $failure;
            }
            .pending {
                background-color: $grey;
            }
            .item-name {
                margin-left: 20px;
                padding-top: 10px;
            }
        }
        li:hover {
            cursor: pointer;
            // background-color: $recce-maroon;
            // color: $white;
        }
    }
}