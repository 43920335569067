@import '../../assets/sass/app.scss';

.profile {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
    .info {
        display: flex;
        div:first-child {
            text-transform: uppercase;
            margin-top: 10px;
        }
        .avatar:hover {
            cursor: pointer;
        }
    }
}
.profile-item-info {
    display: flex;
}
.profile-main-item {
    font-weight: 800;
}
.profile-sub-item {
    font-size: 12px;
}
.profile-edit-item {
    text-decoration: underline;
}