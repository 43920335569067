$background: #F5F5F5;
$unselected: #D8D8D8;
$disabled-grey: #CCCCCC;
$black: #000000;
$white: #FFFFFF;
$charcoal: #333333;
$grey: #666666;
$light-border: #DDDDDD;
$failure: #D50032;
$success: #09b409;
$warning: #ffc475;
$recce-maroon: rgb(74, 1, 1);
$recce-maroon-light: rgba(74, 1, 1, .5);