@import '../variables/colors.scss';

.contents-spinner-container {
    height: 100px;
    // width: calc(100vw - 120px);
}
.spinner-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .spinner {
        position: relative;
        height: 50px;
        width: 50px;
        border: 2px solid transparent;
        border-top-color: $recce-maroon;
        border-left-color: $recce-maroon;
        border-radius: 50%;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
    .spinner::before {
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        content: "";
        border: 2px solid transparent;
        border-top-color: #BA68C8;
        border-left-color: #BA68C8;
        border-radius: 50%;
        -webkit-animation: spinBack 1s linear infinite;
        animation: spinBack 1s linear infinite;
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spinBack {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-720deg);
        transform: rotate(-720deg);
    }
}
@keyframes spinBack {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-720deg);
        transform: rotate(-720deg);
    }
}