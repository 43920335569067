@import '../../assets/sass/app.scss';
.users-container {
    .heading-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .new-table-item {
            background-color: $charcoal;
            color: $white;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            border-radius: 20px;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .cards-container {
        .cards {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}

@media screen and (max-width: 1024px) {
    .users-container {
        margin-top: 40px;
    }
}