.filter-bar{
    height:100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #f5f5f5;
    z-index: 2;
    border-left: 2px solid #4a0101;
    text-align: center;
    }

    .filter-bar input{
        background-color: transparent;
    }

    .filter-bar .select_arrow__1U55d{
        margin-top: 8px;
    }
.filter-bar .sidemenu-head {
    display: flex;
    justify-content: space-between;
    top: 0;
    color: #4a0101;
    font-size: 20px;
    padding: 15px 10px !important;
    border-bottom: 2px solid #4a0101;
    font-weight: bold;
}
.filter-bar .sidemenu-head a{
    cursor: pointer;
  }
.filter-bar button{
    background-color: #4a0101;
    color: #fff;
    padding: 10px 30px;
    border-radius: 0%;
    border: 2px solid #fff;
    font-weight: bold;
}
.filter-btn-grp{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}
.filter-btn-grp .clear-btn{
    background-color: transparent !important;
    border: 2px solid #4a0101 !important;
    color: #4a0101 !important;
}
.filter-bar button:hover{
    background-color: transparent;
    border: 2px solid #4a0101 !important;
    color: #4a0101;
}
.filter-btn{
    position: absolute;
    right: 2%;
    padding: 10px 30px;
    margin-bottom: 10px;
    border: 2px solid #4a0101 !important;
    border-radius: 5%;
    background-color: transparent;
    font-weight: bold;
    color:#4a0101 !important;
    cursor: pointer;
}