@import '../../assets/sass/app.scss';
.card-container {
    background-color: $disabled-grey;
    width: 350px;
    border-radius: 5px;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
    margin: 5px;
    flex-grow: 1;
    &:nth-child(odd) {
        background-color: rgba(74, 1, 1, 0.5);
    }
    // &:nth-child(even) {
    //     background-color: $disabled-grey;
    // }
    .heading {
        // border-top-left-radius: 5px;
        // border-top-right-radius: 5px;
        color: $charcoal;
        font-weight: 400;
        font-size: 12px;
        background-color: $disabled-grey;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: space-between;
        cursor: pointer;
        &:nth-child(odd) {
            background-color: rgba(74, 1, 1, -0.5);
        }
    }
    .break-container {
        display: flex;
        justify-content: center;
        .break {
            border-bottom: solid 1px $recce-maroon;
            width: 75%
        }
    }
    .title {
        font-weight: 800;
        color: $recce-maroon;
    }
    .info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        cursor: pointer;
        .info-item {
            display: flex;
            flex-direction: row;
            margin: 10px;
            min-width: 125px;
             :nth-child(1) {
                margin-right: 5px;
            }
             :nth-child(2) {
                color: $white;
                font-weight: 600;
            }
        }
    }
    .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10px;
        height: 30px;
        // background-color: $recce-maroon;
        color: $white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        &:nth-child(odd) {
            background-color: rgba(74, 1, 1, -0.5);
        }
        div {
            margin-right: 10px;
        }
    }
    .btn-start{
        background-color: $success;
        color: $white;
        border-radius: 4px;
        border: 0;
        padding: 8px 15px;
        margin-bottom: 1rem;
    }
    .btn-stop{
        background-color: $failure;
        color: $white;
        border-radius: 4px;
        border: 0;
        padding: 8px 15px;
        margin-bottom: 1rem;
    }
}


.card {
    display: -webkit-box;/* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;/* OLD - Firefox 19- (doesn't work very well) */
    display: -ms-flexbox;/* TWEENER - IE 10 */
    display: -webkit-flex;/* NEW - Chrome */
    display: flex;/* NEW, Spec - Opera 12.1, Firefox 20+ */
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
    min-height: 20px;
    overflow: hidden;
    width: 350px;
    z-index: 1;
    position: relative;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 
        0 3px 1px -2px rgba(0, 0, 0, 0.2), 
        0 1px 5px 0 rgba(0, 0, 0, 0.12); 
    transition: box-shadow 0.35s cubic-bezier(0.4, 0, 1, 1);
    min-width: 350px;
    margin-bottom: 20px;
    &:nth-child(odd) {
        margin-right: 40px;
    }
    .card-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        .ratio {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 110px;
            .title {
                color: $black;
                font-size: 14px;
                text-transform: capitalize;
            }
            .bubble {
                display: flex;
                justify-content: center;
                width: 20px;
                color: $white;
                background-color: $recce-maroon;
                padding: 15px 15px;
                border-radius: 50%;
                font-weight: 800;
            }
        }
    }
    .info, 
    .title {
        color: rgba(0,0,0,0.87);
        display: block;
        display: -webkit-box;/* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;/* OLD - Firefox 19- (doesn't work very well) */
        display: -ms-flexbox;/* TWEENER - IE 10 */
        display: -webkit-flex;/* NEW - Chrome */
        display: flex;/* NEW, Spec - Opera 12.1, Firefox 20+ */
        -webkit-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 24px 16px 16px;
        .title-text {
            display: block;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            font-size: 24px;
            font-weight: normal;
            line-height: normal;	
            -webkit-transform-origin: 149px 48px;
            -ms-transform-origin: 149px 48px;
            transform-origin: 149px 48px;
            text-transform: capitalize;
            color: $recce-maroon;
        }
        .subtitle-text {
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 1;
        }
        .info-item {
            font-size: 14px;
            color: $charcoal;
            &:nth-child(1) {
                font-weight: 700;
            }
            &:nth-child(3) {
                color: $recce-maroon;
            }
        }
    }
    .start-stop {
        flex-direction: row;
    }
    .elapsed {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 20px;
        color: $recce-maroon;
        .label {
            font-size: 12px;
        }
        .value {
            font-weight: 800;
        }
    }
    .card-body {
        width: 100%;
        margin-left: 20px;
        .multi-dimension-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            div:nth-child(2) {
                margin-right: 10px;
            }
        }
        .dimensions-title {
            font-weight: 800;
            font-size: 12px;
            margin-bottom: 5px;
        }
        .dimensions {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .item {
                display: flex;
                font-size: 12px;
                div:nth-Child(2) {
                    margin-left: 5px;
                    font-weight: 800;
                }
            }
        }
    }
    .card-actions {
        display: -webkit-box;/* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;/* OLD - Firefox 19- (doesn't work very well) */
        display: -ms-flexbox;/* TWEENER - IE 10 */
        display: -webkit-flex;/* NEW - Chrome */
        display: flex;/* NEW, Spec - Opera 12.1, Firefox 20+ */
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 100%;
        height: 52px;
        padding: 8px;
        .btn {
            padding: 0 8px;
            border-radius: 2px;
            outline: none;
            border: none;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            color: rgba(0,0,0,0.87);
            background-color: unset;
        }
        .btn:hover {
            color: $recce-maroon;
            text-decoration: underline;
        }
    }
}
.dummy-card {
    opacity: 0;
}
.timeline-label {
    position: relative;
    top: 15px;
}
.success {
    color: $success;
}
.warning {
    color: $warning;
}
.timeline {
    width: 100%;
    height: 20px;
    display: flex;      
    justify-content: flex-start;  
}
.timeline .events {
    background-color: #606060;
    height: 3px;
    border-radius: 4px;
    margin: 15px 0;
    width: 70%;
}
.timeline .events ol {
    margin: 0;
    padding: 0;
    text-align: center;
}
.timeline .events ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-inline-start: 0;
}
.timeline .events ul li {
    display: inline-block;
    // width: 24.65%;
    margin: 0;
    padding: 0;
}
.timeline .events ul li a {
    font-family: 'Arapey', sans-serif;
    font-style: italic;
    font-size: 14px;
    color: #606060;
    text-decoration: none;
    position: relative;
    top: -28px;
}
.timeline .events ul li a:after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 50%;
    right: auto;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 3px solid #606060;
    background-color: #fff;
    transition: 0.3s ease;
    transform: translateX(-50%);
}
.timeline .events ul li a:hover::after {
    background-color: #194693;
    border-color: #194693;
}
.timeline .events ul li a.selected:after {
    background-color: #194693;
    border-color: #194693;
}

@media screen and (max-width: 795px) {
    .card {
        &:nth-child(odd) {
            margin-right: 20px;
        }
    }
}
@media screen and (max-width: 775px) {
    .card {
        &:nth-child(odd) {
            margin-right: 10px;
        }
    }
}
@media screen and (max-width: 765px) {
    .card {
        &:nth-child(odd) {
            margin-right: 5px;
        }
    }
}
@media screen and (max-width: 755px) {
    .card {
        &:nth-child(odd) {
            margin-right: 0px;
        }
    }
}

.card-additional-info{
    margin-bottom: 15px;
    font-size: 12px;
}