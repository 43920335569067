@import '../../assets/sass/app.scss';


.actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
}

