@import '../../assets/sass/app.scss';
.structures-container {
    .cards-container {
        .cards {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}
.structures-upload-container {
    height: 300px;
    .field-item {
        margin: 40px 0;
    }
    .successful-upload {
        display: flex;
        margin-top: 10px;
        justify-content: center;
        font-weight: 800;
        color: $success;
    }
    .failed-upload {
        display: flex;
        margin-top: 10px;
        justify-content: center;
        font-weight: 800;
        color: $failure;
    }
}
.structure-item-container {
    padding: 10px;
    .structure-item-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .icons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        svg:first-child {
            margin-right: 10px;
            color: $recce-maroon;
        }
        svg:hover {
            cursor: pointer;
        }
    }
    .item-info-section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px;
        border: solid 1px $light-border;
        border-radius: 5px;
        background-color: $disabled-grey;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
        .items {
            display: flex;
            flex-direction: column;
            color: $recce-maroon;
            justify-content: center;
            div {
                padding-bottom: 10px;
                .label { 
                    color: $charcoal;
                    font-weight: 600;
                }
            }
            div:last-child {
                padding-bottom: 0;
            }
        }
        .ratios {
            display: flex;
            .ratio {
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 110px;
                .title {
                    color: $black;
                    font-size: 1.5em;
                }
                .bubble {
                    display: flex;
                    justify-content: center;
                    width: 50px;
                    color: $white;
                    background-color: $recce-maroon;
                    padding: 30px 15px;
                    border-radius: 50%;
                    font-weight: 800;
                }
            }
        }
    }
    .structure-description {
        display: flex;
        flex-direction: column;
        padding: 10px;
        .title {
            font-size: 1.2em;
            color: $charcoal;
            font-weight: 600;
        }
        div {
            margin-bottom: 10px;
        }
    }
    .structure-map {
        position: relative;
        margin-top: 10px;
        flex: 1 ;
        .task-info-expand-colapse {
            position: absolute;
            top: 40px;
            right: 5px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $recce-maroon;
            cursor:pointer;
            z-index: 3;
        }
        .task-info-expand-colapse:before, 
        .task-info-expand-colapse:after {
            content: '';
            display: block;
            background: $white;
            position: relative;
            height: 2em;
            width: 4px;
            border-radius: 1em;
            margin-left: 18px;
            margin-top: 5px;
            -webkit-transition: all 0.25s;
            transition: all 0.25s;
            -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out;
        }
        .task-info-expand-colapse:before {
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }
        .task-info-expand-colapse:after {
            top: -2.4em;
        }
        .task-info-expand-colapse.selected:before {
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        .task-info-expand-colapse.selected:after {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
        .task-info {
            position: absolute;
            top: 60px;
            right: 20px;
            width: 500px;
            // max-height: 400px;
            // border-radius: 10px;
            box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
            // background-color: $white;
            transition: 0.25s;
            // overflow-y: auto;
        }
        .task-info-collapse {
            right: -600px;    
            transition: 0.25s;
        }
        .structure-detail {
            background-color: $white;
            margin-top: 20px;
            padding: 10px 10px 5px 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            .detail-item {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-bottom: 10px;
                font-size: 14px;
                color: $charcoal;
                .title {
                    font-weight: 800;
                }
            }
        }
    }
    .task-table {
        flex: 0 66.66%;
    }
}
.actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
}
.image-data {
    display: flex;
    flex-direction: column;
    .title {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    .title-item:hover {
        color: $recce-maroon;
        cursor: pointer;
        text-decoration: underline;
    }
}
.no-data {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: $recce-maroon;
}

@media screen and (max-width: 1024px) {
    .structures-container, .structure-item-container{
        margin-top: 40px;
    }
}
@media screen and (max-width: 880px) {
    .task-info-collapse {
        right: 0px; 
        display: none;
    }

	.task-info-expand-colapse {
		display: none;
	}

    .task-info {
        width: 100% !important;
        right: 0 !important;
		top: -580px !important;
    }
}

