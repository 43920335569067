.info-container {
    display: flex;
    flex-direction: column;
    width: 200px;
}
.info-container .heading-section {
    font-size: 14px;
    font-weight: 600;
    color: rgb(74, 1, 1);
    padding-bottom: 10px;
    border-bottom: solid 1px rgb(74, 1, 1);
}
.info-container .body-section {
    padding-top: 10px;
}
.info-container .body-section .section-item {
    margin-bottom: 10px;
}
.info-container .body-section .section-item .item-label {
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 5px;
}
.info-container .body-section .section-item .second-item {
    padding-top: 5px;
}
.info-container .body-section .section-item .item-value .multi-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.info-container .body-section .section-item .item-value .multi-items div {
    margin-right: 3px;
}